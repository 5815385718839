<template>
  <div class="event-box">
    <div
      :class="
        events.d100.status === 'done'
          ? 'event-item bg-light-success rounded'
          : 'event-item bg-light-primary rounded'
      "
    >
      <div class="event-item-title">
        <div>⭑ ยอดฝาก 100บาท ขึ้นไป</div>
        <div class="event-item-reward" v-show="events.d100.status !== 'done'">
          รับสิทธ์หมุนกงล้อ 1 สิทธิ์ รางวัลสูงสุด 50 บาท
        </div>
        <div class="event-item-reward" v-show="events.d100.status === 'done'">
          ยินดีด้วยค่าา !! ได้รับเครดิตฟรี {{ events.d100.prize }} บาท
          เรียบร้อยแล้ว
        </div>
      </div>
      <div class="event-item-status">
        <b-button
          type="button"
          class="btn"
          variant="primary"
          v-show="events.d100.status === 'success'"
          @click="onHandleSpin('D100')"
        >
          รับ
        </b-button>
        <div
          class="event-item-status"
          v-show="events.d100.status === 'in-progress'"
        >
          (0/1)<br /><feather-icon size="12" icon="AlertCircleIcon" />
          <a class="condition" href="#">เงื่อนไข</a>
        </div>
        <feather-icon
          v-show="events.d100.status === 'done'"
          icon="CheckIcon"
          size="30"
          variant="success"
        />
      </div>
    </div>
    <div
      :class="
        events.d500.status === 'done'
          ? 'event-item bg-light-success rounded'
          : 'event-item bg-light-primary rounded'
      "
    >
      <div class="event-item-title">
        <div>⭑ ยอดฝาก 500บาท ขึ้นไป</div>
        <div class="event-item-reward" v-show="events.d500.status !== 'done'">
          รับสิทธ์หมุนกงล้อ 1 สิทธิ์ รางวัลสูงสุด 250 บาท
        </div>
        <div class="event-item-reward" v-show="events.d500.status === 'done'">
          ยินดีด้วยค่าา !! ได้รับเครดิตฟรี {{ events.d500.prize }} บาท
          เรียบร้อยแล้ว
        </div>
      </div>
      <div class="event-item-status">
        <b-button
          type="button"
          class="btn"
          variant="primary"
          v-show="events.d500.status === 'success'"
          @click="onHandleSpin('D500')"
        >
          รับ
        </b-button>
        <div
          class="event-item-status"
          v-show="events.d500.status === 'in-progress'"
        >
          (0/1)<br /><feather-icon size="12" icon="AlertCircleIcon" />
          <a class="condition" href="#">เงื่อนไข</a>
        </div>
        <feather-icon
          v-show="events.d500.status === 'done'"
          icon="CheckIcon"
          size="30"
          variant="success"
        />
      </div>
    </div>
    <div
      :class="
        events.d1000.status === 'done'
          ? 'event-item bg-light-success rounded'
          : 'event-item bg-light-primary rounded'
      "
    >
      <div class="event-item-title">
        <div>⭑ ยอดฝาก 1,000บาท ขึ้นไป</div>
        <div class="event-item-reward" v-show="events.d1000.status !== 'done'">
          รับสิทธ์หมุนกงล้อ 1 สิทธิ์ รางวัลสูงสุด 500 บาท
        </div>
        <div class="event-item-reward" v-show="events.d1000.status === 'done'">
          ยินดีด้วยค่าา !! ได้รับเครดิตฟรี {{ events.d1000.prize }} บาท
          เรียบร้อยแล้ว
        </div>
      </div>
      <div class="event-item-status">
        <b-button
          type="button"
          class="btn"
          variant="primary"
          v-show="events.d1000.status === 'success'"
          @click="onHandleSpin('D1000')"
        >
          รับ
        </b-button>
        <div
          class="event-item-status"
          v-show="events.d1000.status === 'in-progress'"
        >
          (0/1)<br /><feather-icon size="12" icon="AlertCircleIcon" />
          <a class="condition" href="#">เงื่อนไข</a>
        </div>
        <feather-icon
          v-show="events.d1000.status === 'done'"
          icon="CheckIcon"
          size="30"
          variant="success"
        />
      </div>
    </div>
    <div
      :class="
        events.dg500.status === 'done'
          ? 'event-item bg-light-success rounded'
          : 'event-item bg-light-primary rounded'
      "
    >
      <div class="event-item-title">
        <div>⭑ ฝากเงิน 500 บาทขึ้นไป นาทีทอง 20:00-21:00</div>
        <div class="event-item-reward" v-show="events.dg500.status !== 'done'">
          รับสิทธ์หมุนกงล้อ 1 สิทธิ์ รางวัลสูงสุด 500 บาท
        </div>
        <div class="event-item-reward" v-show="events.dg500.status === 'done'">
          ยินดีด้วยค่าา !! ได้รับเครดิตฟรี {{ events.dg500.prize }} บาท
          เรียบร้อยแล้ว
        </div>
      </div>
      <div class="event-item-status">
        <b-button
          type="button"
          class="btn"
          variant="primary"
          v-show="events.dg500.status === 'success'"
          @click="onHandleSpin('DG500')"
        >
          รับ
        </b-button>
        <div
          class="event-item-status"
          v-show="events.dg500.status === 'in-progress'"
        >
          (0/1)<br /><feather-icon size="12" icon="AlertCircleIcon" />
          <a class="condition" href="#">เงื่อนไข</a>
        </div>
        <feather-icon
          v-show="events.dg500.status === 'done'"
          icon="CheckIcon"
          size="30"
          variant="success"
        />
      </div>
    </div>
    <!-- <div
      :class="
        events.lose1000.status === 'done'
          ? 'event-item bg-light-success rounded'
          : 'event-item bg-light-primary rounded'
      "
    >
      <div class="event-item-title">
        <div>⭑ เล่นเสียเกิน 1,000 บาท ให้เราปลอบ</div>
        <div class="event-item-reward">
          ลุ้นรับเครดิตฟรี 200บาท วันละ 3 รางวัล
        </div>
      </div>
      <div class="event-item-status">
        <b-button
          type="button"
          class="btn"
          variant="primary"
          v-show="events.lose1000.status === 'success'"
          @click="onHandleSpin('lose1000.status')"
        >
          รับ
        </b-button>
        <div
          class="event-item-status"
          v-show="events.lose1000.status === 'in-progress'"
        >
          <br /><feather-icon size="12" icon="AlertCircleIcon" />
          <a class="condition" href="#">เงื่อนไข</a>
        </div>
        <feather-icon
          v-show="events.lose1000.status === 'done'"
          icon="CheckIcon"
          size="30"
          variant="success"
        />
      </div>
    </div> -->
  </div>
</template>
<style scoped>
.event-box {
  display: block;
}

.event-item {
  display: flex;
  font-size: 13px;
  color: black;
  padding: 7px;
  padding-left: 10px;
  margin-top: 5px;
}

.event-item .condition {
  text-decoration: underline;
  color: blue;
  font-size: 11px;
  margin-left: 3px;
}

.event-item-title {
  flex: 4;
  color: #917ddc;
  /* border: 1px solid #000; */
}

.event-item-reward {
  color: #808080 !important;
  font-size: 11px;

  /* border: 1px solid #000; */
}

.event-item-status {
  flex: 1;
  padding-right: 4px;
  /* border: 1px solid #000; */
  text-align: end;
}

.menu-item-title {
  min-width: 50px;
  font-size: 12px;
  margin-top: 3px;
}

.active-menu {
  color: #fff;
  background-color: #beadfa;
}
</style>
<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton
  },
  props: {
    // Define a prop named 'message' with type String
    handleSpin: {
      type: Function,
      required: true
    },
    events: {
      required: true
    }
  },
  data() {
    return {
      loading: true
    }
  },
  methods: {
    onHandleSpin(event) {
      this.handleSpin(event)
    }
  }
}
</script>
