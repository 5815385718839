<template>
  <div>
    <b-card
      class="mt-2"
      title="ข้อเสนอสุดพิเศษ (เวลาจำกัด) 🎉"
      v-show="remainingTime"
    >
      <b-card-text>
        <b-row>
          <b-col cols="8">
            <div>
              <Countdown />
            </div>
            <div class="d-flex" style="margin-top: 5px">
              <div>
                <b-img
                  :src="require('@/assets/images/icons/coupon.png')"
                  width="50"
                  style="margin-right: 10px"
                />
              </div>
              <div>
                <b-img
                  :src="require('@/assets/images/icons/coupon.png')"
                  width="50"
                  style="margin-right: 10px"
                />
              </div>
              <div>
                <b-img
                  :src="require('@/assets/images/icons/coupon.png')"
                  width="50"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="4">
            <b-img :src="require('@/assets/images/congrats.png')" fluid />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="flex">
              <b-alert variant="warning" show class="mt-1">
                <div class="alert-body reward-text">
                  <div class="deposit-text">
                    <feather-icon icon="InfoIcon" class="mr-50" />
                    เพียงฝากเงินครั้งแรก
                    <span
                      style="text-decoration: underline; font-weight: bolder !important; font-size: 15px;"
                    >
                      "ตอนนี้" </span
                    >รับข้อเสนอสุดพิเศษไปเลย
                  </div>
                </div>
              </b-alert>
            </div>
            <div class="flex">
              <b-alert variant="success" show class="mt-1">
                <div class="alert-body reward-text">
                  <div v-show="deal === '1'">
                    <feather-icon icon="CheckCircleIcon" class="mr-50" />
                    รับเครดิตเพิ่ม
                    <span
                      style="text-decoration: underline;font-weight: bolder !important; font-size: 15px;"
                      >20%</span
                    >
                    จากยอดฝาก<span
                      style="text-decoration: underline;font-weight: bolder !important; font-size: 15px;"
                      >ฟรี ไม่มีเทิร์น</span
                    >
                  </div>
                  <div v-show="deal === '2'">
                    <feather-icon icon="CheckCircleIcon" class="mr-50" />
                    ทำ 2 เทิร์น
                    <span
                      style="text-decoration: underline;font-weight: bolder !important; font-size: 15px;"
                      >"ถอนได้ไม่จำกัดยอด"</span
                    >
                    จากปรกติถอนได้เพียง {{ maxWithDraw }}
                  </div>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-card title="บัญชีฝากเงิน 💸" v-show="member && !member.is_deposit">
      <b-card-text>
        <b-row>
          <b-col cols="3" class="d-flex align-self-center">
            <b-img
              :src="require('@/assets/images/logo/scb-logo.png')"
              blank-color="#ccc"
              width="75"
              height="75"
              alt="placeholder"
              rounded="circle"
              class="mr-2"
            />
          </b-col>

          <b-col cols="9">
            <h3 class="media-heading">
              ธนาคารไทยพาณิชย์
            </h3>
            <h4 class="media-heading">
              ชื่อบัญชี {{ bank ? bank.bank_account_name : '' }}
            </h4>
            <h4>
              {{ bank ? bank.bank_account_no : '' }}
              <b-button
                v-clipboard:copy="bank ? bank.bank_account_no : ''"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                size="sm"
                class="ml-2"
              >
                คัดลอก
              </b-button>
            </h4>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-card class="mt-2" :title="'กิจกรรมรายวัน 📋: ' + formatDate(new Date())">
      <b-card-text>
        <EventBox :handleSpin="openSpinModal" :events="this.events" />
      </b-card-text>
    </b-card>
    <b-card title="กระเป๋า 💸">
      <b-card-text>
        <balance-box />
      </b-card-text>
    </b-card>
    <!-- <b-card title="เกมส์มาใหม่ล่าสุด">
      <b-card-text>
        <slider-item />
      </b-card-text>
    </b-card> -->

    <!-- <b-row>
      <b-col sm="6" class="mb-4">
        <b-card title="โปรแกรมคืนยอดเสีย" sm="6">
          <div class="bg-light-primary rounded p-2">
            <div>เลเวล: 1</div>
            <div>คืนยอดสูงสุด: 10% [500บาท]</div>
            <div>
              ค่าประสบการณ์: 25%
              <b-progress style="margin-top: 3px;" value="25" max="100" />
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row> -->
    <b-modal
      centered
      hide-header-close
      no-close-on-backdrop
      content-class="shadow"
      title="กงล้อนำโชค"
      hide-footer
      v-model="showSpinModal"
    >
      <SpinWheel
        :eventName="event"
        :prizes="prizes"
        :onDone="onSpinDone"
      ></SpinWheel>
      <b-alert show variant="warning">
        <div class="alert-body mt-2">
          ! กด R เมื่อหมุนกงล้อแล้วเงินรางวัลจะเข้าอัตโนมัติ
        </div>
      </b-alert>
    </b-modal>
  </div>
</template>
<style scoped>
.deal-title {
  padding: 10px;
  margin-bottom: 5px;
}

.reward-text {
  font-size: 14px !important;
}

.deposit-text {
  color: #ec811c;
}
</style>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BModal,
  BAlert,
  BImg,
  BButton
  // BProgress
} from 'bootstrap-vue'
import BalanceBox from '../component/BalanceBox.vue'
import EventBox from '../component/EventBox.vue'
import SpinWheel from '../component/SpinWheel.vue'
import Countdown from '../component/Countdown.vue'

import { getUserData } from '../../auth/utils'

export default {
  components: {
    BCard,
    BCardText,
    BalanceBox,
    EventBox,
    Countdown,
    BRow,
    BCol,
    BImg,
    SpinWheel,
    BModal,
    BAlert,
    BButton
    // BProgress
  },
  props: {
    events: {
      required: true
    },
    updateEvent: {
      required: true
    },
    bank: {
      required: true
    },
    member: {
      required: true
    }
  },
  directives: {
    Ripple
  },
  data() {
    return {
      showSpinModal: false,
      memberInfo: null,
      items: [],
      count: 5,
      event: '',
      prizes: [],
      remainingTime: false,
      deal: '0',
      maxWithDraw: ''
    }
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'คัดลอกเลขที่บัญชีเรียบร้อยแล้ว',
          icon: 'BellIcon',
          variant: 'success'
        }
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'ไม่สามารถคัดลอกข้อมูลได้',
          icon: 'BellIcon',
          variant: 'danger'
        }
      })
    },
    async determineDeal() {
      if (this.memberInfo.promo_id === null) {
        this.deal = '1'
      } else {
        const resp = await this.$http.get(
          `member/promotion/${this.memberInfo.promo_id}`
        )
        console.log('promo', resp.data)
        this.deal = '2'
        this.maxWithDraw = `${resp.data.max_withdraw} บาท`
      }
      console.log('DEAL', this.deal)
    },
    async getSpinPrizeFromEvent(event) {
      console.log('getSpinPrizeFromEvent', event)

      const resp = await this.$http.post(`member/event/prize`, {
        event
      })
      console.log(resp.data.prize)
      this.event = event
      this.prizes = resp.data.prize
      console.log(this.prizes)
    },
    async openSpinModal(event) {
      console.log(event)
      await this.getSpinPrizeFromEvent(event)
      this.showSpinModal = true
    },
    async onSpinDone(prize, event) {
      console.log('onSpinDone', prize)
      await this.$http.post(`member/event/stamp`, {
        event,
        prize
      })
      this.showSpinModal = false
      window.location.reload()
    },
    formatDate(date) {
      // Simple date formatting to DD-MM-YYYY
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    calculateRemainingTime() {
      const now = new Date() // Current time
      const registerTime = new Date(this.memberInfo.created_at)

      // Add 8 hours to register time
      const targetTime = new Date(registerTime.getTime() + 8 * 60 * 60 * 1000)

      // Calculate the time difference in milliseconds
      const timeDiff = targetTime - now

      if (timeDiff > 0) {
        this.remainingTime = true
      } else {
        this.remainingTime = false
      }
      console.log('calculateRemainingTime Status', this.remainingTime)
    }
  },
  async beforeMount() {
    this.memberInfo = getUserData()
    this.calculateRemainingTime()
    await this.determineDeal()
  }
}
</script>
