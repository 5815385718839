<template>
  <div class="mt-4">
    <b-container class="bv-example-row ">
      <b-row class="justify-content-sm-center text-center">
        <b-col cols="12" sm="6">
          <b-link :href="url">
            <b-img fluid src="../../assets/images/icons/sport.png" />
          </b-link>
          <div class="p-1">
            <b-button
              :href="url"
              type="button"
              class="btn btn-block"
              variant="primary"
              target="_blank"
            >
              เข้าเดิมพัน
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BImg, BContainer, BRow, BCol, BButton, BLink } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BContainer,
    BRow,
    BCol,
    BButton,
    BLink
  },
  props: {
    url: {
      required: true
    }
  }
}
</script>
