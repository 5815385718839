<template>
  <div class="d-flex justify-content-center">
    <!-- type: canvas -->
    <FortuneWheel
      style="width: 300px"
      :canvas="canvasOptions"
      :prizes="this.prizes"
      :verify="cavansVerify"
      @rotateStart="onCanvasRotateStart"
      @rotateEnd="onRotateEnd"
      :disabled="false"
    />
  </div>
</template>

<script>
import FortuneWheel from 'vue-fortune-wheel'
import 'vue-fortune-wheel/lib/vue-fortune-wheel.css'

export default {
  components: {
    FortuneWheel
  },
  props: {
    // Define a prop named 'message' with type String
    prizes: {
      type: Array,
      required: true
    },
    onDone: {
      type: Function,
      required: true
    },
    eventName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      cavansVerify: true, // Whether the turntable in canvas mode is enabled for verification
      canvasOptions: {
        borderWidth: 3,
        borderColor: '#beadfa',
        btnText: 'Ʀ',
        btnWidth: 100
      }
    }
  },
  methods: {
    onImageRotateStart() {
      console.log('onRotateStart')
    },
    async onCanvasRotateStart(rotate) {
      if (this.cavansVerify) {
        console.log('11')
        const resp = await this.DoServiceVerify()
        if (resp.verify === true) {
          rotate()
        }
        // this.DoServiceVerify(verified, 100).then(verifiedRes => {
        //   if (verifiedRes) {
        //     console.log('Verification passed, start to rotate')
        //     rotate() // Call the callback, start spinning
        //     this.cavansVerify = false // Turn off verification mode
        //   } else {
        //     alert('Failed verification')
        //   }
        // })

        return
      }
      console.log('onCanvasRotateStart')
    },
    async onRotateEnd(prize) {
      await this.onDone(prize.value, this.eventName)
    },
    // Simulate the request back-end interface, verified: whether to pass the verification, duration: delay time
    async DoServiceVerify() {
      const resp = await this.$http.post(`member/event/verify`, {
        event: this.eventName
      })
      return resp.data
    }
  }
}
</script>
<style>
.fw-btn__btn {
  background-color: #beadfa !important;
}

.fw-btn__btn:before {
  border-bottom: 18px solid #beadfa !important;
}
</style>
