<template>
  <div>
    <b-container class="mb-4 mt-2">
      <b-row>
        <b-alert
          variant="warning"
          :show="hideProvider && hideProvider.isHide"
          class="mt-1"
        >
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50" />
            เนื่องจากลูกค้ารับโปรโมชั่นฝากเงินครั้งแรก
            จะสามารถเข้าเล่นคาสิโนสดได้เพียงค่ายเดียว
            เพื่อเป็นการป้องกันแก๊งมิจชาชีพล่าโปรโดยการแทงสวน
            <br /><br />เมื่อลูกค้าถอนเงินครั้งแรก หรือ
            ฝากเงินครั้งต่อไปจะสามารถเข้าเล่นยได้ทุกค่ายตามปรกติ
            <br /><br />จึงเรียนมาเพื่อทราบ
          </div>
        </b-alert>
        <b-col cols="6" sm="3" v-for="(list, index) in providers" :key="index"
          ><provider-item
            :img="list.logoTransparentURL"
            :providerName="list.providerName"
            :buttonHref="
              `/gameplay/login?casino=${list.provider}&gameId=${list.gameId}`
            "
        /></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BRow, BCol, BContainer, BAlert } from 'bootstrap-vue'
import ProviderItem from '../component/ProviderItem.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    ProviderItem,
    BAlert
  },
  props: {
    // Define a prop named 'message' with type String
    providers: {
      type: Array,
      required: true
    },
    hideProvider: {
      required: true
    }
  },
  data() {
    return {
      loading: true,
      filteredProviders: []
    }
  },
  beforeMount() {
    console.log('hideProvider', this.hideProvider)
  }
}
</script>
