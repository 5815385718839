<template>
  <div>
    <b-container class="mb-4 mt-2">
      <b-row>
        <b-col cols="6" sm="3" v-for="(list, index) in providers" :key="index"
          ><provider-item
            :img="list.logoTransparentURL"
            :providerName="list.providerName"
            :buttonHref="`/gameplay/slots?providor=${list.provider}`"
        /></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BRow, BCol, BContainer } from 'bootstrap-vue'
import ProviderItem from '../component/ProviderItem.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    ProviderItem
  },
  props: {
    // Define a prop named 'message' with type String
    providers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: true
    }
  },
  methods: {},
  beforeMount() {
    console.log('providers slot', this.providers)
  }
}
</script>
