<template>
  <div class="countdown-timer">
    <div class="time-box bg-light-danger">
      <span class="countdown-element hours">{{ hours }}</span> ชั่วโมง
    </div>
    <div class="time-box bg-light-danger">
      <span class="countdown-element minutes">{{ minutes }}</span> นาที
    </div>
    <div class="time-box bg-light-danger">
      <span class="countdown-element seconds">{{ seconds }}</span> วินาที
    </div>
  </div>
</template>

<script>
import { getUserData } from '../../auth/utils'

export default {
  data() {
    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
      interval: null,
      destinationTime: null,
      memberInfo: null
    }
  },
  mounted() {
    this.memberInfo = getUserData()
    this.calculateRemainingTime()
    this.setDestinationTime()
    this.startCountdown()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    calculateRemainingTime() {
      const now = new Date() // Current time
      const registerTime = new Date(this.memberInfo.created_at) // Convert register time to Date object
      console.log('registerTime', registerTime)

      // Add 8 hours to register time
      const targetTime = new Date(registerTime.getTime() + 8 * 60 * 60 * 1000)

      // Calculate the time difference in milliseconds
      const timeDiff = targetTime - now

      if (timeDiff > 0) {
        // Convert milliseconds to hours, minutes, and seconds
        this.hours = Math.floor(timeDiff / (1000 * 60 * 60))
        this.minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))
        this.seconds = Math.floor((timeDiff % (1000 * 60)) / 1000)

        this.remainingTime = `${this.hours}h ${this.minutes}m ${this.seconds}s`
      }
    },
    setDestinationTime() {
      // Get the current date and time
      const now = new Date()

      // Add the remaining time to the current time
      now.setHours(now.getHours() + this.hours)
      now.setMinutes(now.getMinutes() + this.minutes)
      now.setSeconds(now.getSeconds() + this.seconds)

      this.destinationTime = now
    },
    startCountdown() {
      this.interval = setInterval(() => {
        const now = new Date().getTime()
        const diff = this.destinationTime - now

        if (diff <= 0) {
          clearInterval(this.interval)
          this.resetTime()
          return
        }

        this.updateTime(diff)
      }, 1000)
    },
    updateTime(diff) {
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((diff % (1000 * 60)) / 1000)

      this.hours = hours < 10 ? `0${hours}` : hours
      this.minutes = minutes < 10 ? `0${minutes}` : minutes
      this.seconds = seconds < 10 ? `0${seconds}` : seconds
    },
    resetTime() {
      this.hours = '00'
      this.minutes = '00'
      this.seconds = '00'
    }
  }
}
</script>

<style scoped>
.countdown-timer {
  display: flex;
  gap: 10px;
  text-align: center;
}
.time-box {
  margin-top: -10px;
  padding: 10px;
  border-radius: 5px;
  width: 60px;
}
.countdown-element {
  display: block;
  font-size: 18px;
  font-weight: bold;
}
</style>
