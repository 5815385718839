<template>
  <div>
    <b-container class="bv-example-row px-1">
      <b-row class="justify-content">
        <b-col
          v-for="(list, index) in gameLists"
          :key="index"
          cols="4"
          sm="3"
          class="item-box"
        >
          <GameItem
            :provider="list.provider"
            :gameId="list.id"
            :img="list.image.vertical"
            :providerName="list.gameName"
            :onGameSelected="handleGameSelected"
          />
        </b-col> </b-row
    ></b-container>
  </div>
</template>

<script>
import { BRow, BContainer, BCol } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import GameItem from '../component/GameItem.vue'

export default {
  components: {
    BRow,
    BContainer,
    GameItem,
    BCol
  },
  props: {
    gameLists: {
      required: true
    }
  },
  directives: {
    Ripple
  },
  data() {
    return {
      lists: [],
      providers: [],
      selected: '',
      gameTypes: []
    }
  },
  async beforeMount() {
    // await this.getSlotsGames()
    // await this.getProviders()
  },

  methods: {
    handleGameSelected(provider, gameId) {
      window.open(
        `/gameplay/login/slot?p=${provider}&g=${gameId}&t=Fish`,
        '_blank'
      )
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.game-block:hover {
  .play-button {
    display: block;
  }
}

.play-button {
  display: none;
}

.type-block {
  padding: 4px;
}

.item-box {
  padding: 5px !important;
}
</style>
