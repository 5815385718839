<template>
  <div class="cover-box">
    <a :href="buttonHref">
      <b-img fluid :src="img"></b-img>
      <span class="provider-name">{{ providerName }}</span>
    </a>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg
  },
  props: {
    img: {
      required: true,
      type: [String]
    },
    providerName: {
      required: true,
      type: [String]
    },
    buttonHref: {
      required: false,
      type: [String],
      default: '/'
    }
  }
}
</script>
<style scoped>
.cover-box {
  display: block;
  border: 1.5px solid #beadfa;
  padding: 20px;
  margin-top: 10px;
  border-radius: 15px;
  background: linear-gradient(
    to bottom right,
    #beadfa,
    #fff
  ); /* Gradient from top-left to bottom-left */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.provider-name {
  display: block;
  padding: 5px;
  border: 1px dashed #beadfa;
  border-radius: 10px;
  font-weight: bold;
  color: #7259cc;
  text-shadow: 1px 1px 0 #fff, /* Top-left shadow */ -1px -1px 0 #fff,
    /* Bottom-right shadow */ 1px -1px 0 #fff,
    /* Top-right shadow */ -1px 1px 0 #fff; /* Bottom-left shadow */
}
</style>
