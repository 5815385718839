<template>
  <div>
    <GameMenu :menu="activeMenu" :onSelectMenu="onSelectMenu" />
    <b-overlay
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      :show="loading"
      rounded="sm"
    >
      <div id="home" v-show="this.activeMenu === 'Home'">
        <HomePage
          :events="events"
          :updateEvent="getDailyEvents"
          :bank="bank"
          :member="member"
        />
      </div>
      <div id="slot-provider" v-show="this.activeMenu === 'Slot'">
        <SlotProviderPage :providers="slotProviders" />
      </div>
      <div id="casino-provider" v-show="this.activeMenu === 'Casino'">
        <CasinoPage :providers="casinoProviders" :hideProvider="hideInfo" />
      </div>
      <div id="football-provider" v-show="this.activeMenu === 'Football'">
        <FootballPage :url="sportUrl" />
      </div>
      <div id="more-games" v-show="this.activeMenu === 'MoreGames'">
        <MoreGamesPage :gameLists="fishingGames" />
      </div>
      <div id="lotto-provider" v-show="this.activeMenu === 'Lotto'">
        <LottoPage :url="lottoURL" />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'

import { getUserData } from '../auth/utils'
import GameMenu from './component/GameMenu.vue'
import HomePage from './v2/HomePage.vue'
import SlotProviderPage from './v2/SlotProviderPage.vue'
import CasinoPage from './v2/CasinoPage.vue'
import FootballPage from './v2/FootballPage.vue'
import LottoPage from './v2/LottoPage.vue'
import MoreGamesPage from './v2/MoreGamesPage.vue'

import 'animate.css'

export default {
  components: {
    GameMenu,
    HomePage,
    BOverlay,
    SlotProviderPage,
    CasinoPage,
    FootballPage,
    LottoPage,
    MoreGamesPage
  },
  data() {
    return {
      activeMenu: 'Home',
      user: null,
      bank: null,
      member: null,
      hideInfo: null,
      loading: false,
      slotProviders: [],
      casinoProviders: [],
      events: {},
      fishingGames: [],
      lottoURL: '',
      sportUrl: '',
      menu: [
        {
          title: 'ฝาก',
          icon: 'DollarSignIcon',
          color: 'light-primary',
          routing: 'deposit'
        },
        {
          title: 'ถอน',
          icon: 'SmileIcon',
          color: 'light-warning',
          routing: 'withdraw'
        },
        {
          title: 'โปรโมชั่น',
          icon: 'StarIcon',
          color: 'light-info',
          routing: 'promotion'
        },
        {
          title: 'รับเครดิตเงินคืน',
          icon: 'PackageIcon',
          color: 'light-success',
          routing: 'cashback'
        }
        // {
        //   title: 'รายได้ชวนเพื่อน',
        //   icon: 'UsersIcon',
        //   color: 'light-success'
        // },
        // {
        //   title: 'ตั้งค่า',
        //   icon: 'SettingsIcon',
        //   color: 'light-danger'
        // }
      ]
    }
  },
  async beforeMount() {
    this.loading = true
    this.user = getUserData()
    this.member = await this.getMemberInfo()

    try {
      await this.getDailyEvents()
      await this.getProviders()
      await this.getCasinoProviders()
      await this.getFirstRegisInfo()
      await this.getFishingGames()
      await this.getSportLogin()
      await this.getActiveBank()
      await this.getLottoURL()
      // await this.getMemberLevel()
      this.loading = false
    } catch (err) {
      console.log('SOME_ERROR', err)
      this.loading = false
    }
  },
  methods: {
    async getMemberInfo() {
      const member = await this.$http.get('member/info')

      if (member.status === 200) {
        return member.data
      }
      return null
    },
    onSelectMenu(menu) {
      this.activeMenu = menu
    },
    playButtonClick() {
      if (this.member.agent_username === null) {
        this.$swal({
          title: 'กำลังสร้างบัญชี',
          text: 'เรียนลูกค้าที่เคารพ กรุณาทำการรีเฟรชหน้าจออีกครั้ง ค่ะ',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          showClass: {
            popup: 'animate__animated animate__flipInX'
          },
          buttonsStyling: false
        })
      } else {
        console.log('PLAY')
        const routeData = this.$router.resolve({
          name: 'gameplay'
        })
        window.open(routeData.href, '_blank')
      }
    },
    async getProviders() {
      const lists = await this.$http.get(`member/slot/provider`)
      this.slotProviders = lists.data.lists
    },
    async getCasinoProviders() {
      const lists = await this.$http.get(`member/casino/provider`)
      this.casinoProviders = lists.data.lists
    },
    async getFishingGames() {
      const lists = await this.$http.post(`member/game/list/common`, {
        tab: 'Fish'
      })
      this.fishingGames = lists.data.result
    },
    async getLottoURL() {
      const resp = await this.$http.post(`member/lotto/start`, {
        page: 'home'
      })
      this.lottoURL = resp.data.result.uri
    },
    async getSportLogin() {
      const url = await this.$http.post('member/redirectLink', {
        page: 'sports'
      })
      this.sportUrl = url.data.url
    },
    async getDailyEvents() {
      const resp = await this.$http.post(`member/event/progress`)
      console.log(resp.data.events)
      this.events = resp.data.events
    },
    async getActiveBank() {
      const b = await this.$http.post('/member/bank/active', {
        domain: 'rizz888.com'
      })
      this.bank = b.data.bank
    },
    // async getMemberLevel() {
    //   const resp = await this.$http.post(`member/level`)
    //   console.log(resp.data)
    // },
    async getFirstRegisInfo() {
      const resp = await this.$http.post(`member/first-regis`)
      this.hideInfo = resp.data.hideInfo
      if (this.hideInfo.isHide === true) {
        this.casinoProviders = this.casinoProviders.filter(
          provider => provider.provider === this.hideInfo.restrictProvider
        )
      }
      console.log('getFirstRegisInfo', this.hideInfo)
    }
  }
  // watch: {
  //   // Watch for changes in `activeDiv` to fetch data when a div becomes visible
  //   activeMenu(newVal) {
  //     console.log('NEW', newVal)
  //   }
  // }
}
</script>

<style></style>
