<template>
  <div class="game-menu">
    <div
      :class="`menu-item ${menu === 'Home' ? 'active-menu' : ''}`"
      @click="handleSelectMenu('Home')"
    >
      <div><b-img width="25" src="../../assets/images/icons/home.png" /></div>
      <div class="menu-item-title">หน้าแรก</div>
    </div>
    <div
      :class="`menu-item ${menu === 'Slot' ? 'active-menu' : ''}`"
      @click="handleSelectMenu('Slot')"
    >
      <div><b-img width="25" src="../../assets/images/icons/slot.png" /></div>
      <div class="menu-item-title">สล็อต</div>
    </div>
    <div
      :class="`menu-item ${menu === 'Casino' ? 'active-menu' : ''}`"
      @click="handleSelectMenu('Casino')"
    >
      <div><b-img width="25" src="../../assets/images/icons/casino.png" /></div>
      <div class="menu-item-title">คาสิโน</div>
    </div>
    <div
      :class="`menu-item ${menu === 'Football' ? 'active-menu' : ''}`"
      @click="handleSelectMenu('Football')"
    >
      <div>
        <b-img width="25" src="../../assets/images/icons/football.png" />
      </div>
      <div class="menu-item-title">กีฬา</div>
    </div>
    <div
      :class="`menu-item ${menu === 'MoreGames' ? 'active-menu' : ''}`"
      @click="handleSelectMenu('MoreGames')"
    >
      <div><b-img width="25" src="../../assets/images/icons/fish.png" /></div>
      <div class="menu-item-title">ยิงปลา</div>
    </div>
    <div
      :class="`menu-item ${menu === 'Lotto' ? 'active-menu' : ''}`"
      @click="handleSelectMenu('Lotto')"
    >
      <div><b-img width="25" src="../../assets/images/icons/lotto.png" /></div>
      <div class="menu-item-title">หวย</div>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg
  },
  props: {
    // Define a prop named 'message' with type String
    menu: {
      type: String,
      required: true
    },
    onSelectMenu: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      activeMenu: 'Home'
    }
  },
  methods: {
    handleSelectMenu(menu) {
      this.onSelectMenu(menu)
    }
  },
  beforeMount() {
    console.log('PRop', this.menu)
  }
}
</script>

<style scoped>
.game-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.menu-item {
  color: #000;
  padding: 5px;
  border-radius: 10%;
  text-align: center;
  border: 1px solid #beadfa;
  margin-right: 4px;
}

.menu-item-title {
  min-width: 50px;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 800;
}

.active-menu {
  color: #fff;
  background-color: #beadfa;
}
</style>
